<template>
  <div class="login">
    <div class="mask" v-if="mask"></div>
    <div class="meta">
      <img src="@/assets/images/logo.png" alt="" />
      <p>慧言</p>
    </div>
    <div class="info phone">
      <van-field
        v-model="phone"
        placeholder="请输入手机号"
        type="number"
        maxlength="11"
      />
    </div>
    <div class="info yanzheng">
      <div class="yanzheng-flex">
        <van-field
          v-model="yanzheng"
          placeholder="请输入验证码"
          type="number"
          maxlength="6"
        />
        <van-button
          v-if="showtime === null"
          @click.prevent="getCode($event)"
          :disabled="disabled"
          class="getCode"
      
          >获取验证码</van-button
        >
        <i v-else class="time">{{ showtime }}</i>
      </div>
    </div>
    <van-button class="btn" @click.native="loginIn" 
      >绑 定</van-button
    >
    <!-- <span class="skip" @click="skipBind">跳过</span> -->
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      phone: "",
      yanzheng: "",
      showtime: null,
      clickTimes: 1,
      disabled: true,
      showlogin: false,
      code: "",
      userData: {},
      openId: "",
      mask: true,
      codeBind: false,
      skip: false,
    };
  },
  created() {
    document.body.style.background = "white";
  },
  watch: {
    phone(cur) {
      let reg = /^1(2|3|4|5|6|7|8|9)\d{9}$/;
      this.disabled = !reg.test(cur);
    },
  },
  mounted() {
    if (this.deviceEnvir() == "WECHAT") {
      if (this.$route.query.redirect) {
        // 从绑定邀请码页面跳转回来
        this.mask = false;
      } else {
        // 正常微信登录
        localStorage.setItem("callbackWay",'wx')
        this.code = location.href.split("code=")[1].split("&")[0];
        
        this.codeLogin();
      }
    } else {
      this.mask = !(this.$route.query.openId || this.$route.query.redirect);
    }
  },
  methods: {
    skipBind() {
      this.$dialog
        .confirm({
          message: "是否确定跳过绑定手机号?",
          confirmButtonText: "确定",
        })
        .then(() => {
          this.skip = true;
          this.phone = "";
          this.loginIn();
        })
        .catch(() => {
          this.skip = false;
        });
    },
    deviceEnvir() {
      var u = window.navigator.userAgent;
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; // 判断是否是 android终端
      var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // 判断是否是 ios终端
      var isWeiChat =
        u.toLowerCase().match(/MicroMessenger/i) == "micromessenger"; // 判断是否是微信环境
      return isWeiChat
        ? "WECHAT"
        : isIOS
        ? "IOS"
        : isAndroid
        ? "ANDROID"
        : "H5";
    },
    codeLogin() {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      let arr = { code: this.code};
      this.$api.login.wxLoginCode(arr).then((res) => {
      
        if (res.code === 200) {
          if (res.data.wechatOpenId) {
            this.openId = res.data.wechatOpenId;
            localStorage.setItem("openId", res.data.wechatOpenId);
          }
          this.codeBind = res.data.codeBind;
          this.saveUserInfo(res);
          res.data.token&& localStorage.setItem("rtoken",res.data.token)
          if (res.data.bind) {
            this.$toast({
              message: "登录成功",
              duration: 1500,
              onClose: () => {
                if (res.data.codeBind) {
                  localStorage.setItem("token",localStorage.getItem('rtoken'))
                  this.$router.replace("/scene");
                } else {
                  this.$router.replace(`/inviteIs`);
                }
              },
            });
          } else {
            this.mask = false;
          }
        }
        this.$toast.clear();
      });
    },

    isWX() {
      const ua = navigator.userAgent.toLowerCase();
      const isWeixin = ua.indexOf("micromessenger") !== -1;
      const isInApp = /(^|;\s)app\//.test(ua);
      if (isWeixin) {
        if (window.__wxjs_environment === "miniprogram") {
          return "wxapp";
        } else {
          return "wxh5";
        }
      } else {
        if (!isInApp) {
          return "browser";
        } else {
          return "app";
        }
      }
    },
    // 获取验证码
    getCode() {
      // 点击太过频繁
      // if (event.target.disabled) {
      //   return false;
      // }
      let reg = /^1(2|3|4|5|6|7|8|9)\d{9}$/;
      if (!reg.test(this.phone)) {
        // 电话号码有误
        this.$toast("电话号码有误");
        return false;
      } else {
        // this.disabled = true;
        // return
        this.$api.login
          .getCode({
            mobile: this.phone,
          })
          .then((res) => {
            console.log("获取验证码", res);
            // this.disabled = false;
            this.clickTimes += 1;
            if (res.code === 200) {
              this.countDown(60);
              this.yanzheng = res.data.code;
            } else {
              this.$toast(res.errmsg);
            }
            // if (res.data.code === 200) {
            //   this.countDown(60);
            //   this.yanzheng = res.data.checkCode;
            // }
          });
      }
    },
    // 倒计时显示处理
    countDownText(s) {
      this.showtime = `${s}s后再获取`;
    },
    // 倒计时 60秒 不需要很精准
    countDown(times) {
      const self = this;
      // 时间间隔 1秒
      const interval = 1000;
      let count = 0;
      self.timeCounter = setTimeout(countDownStart, interval);
      // 定时器中的方法
      function countDownStart() {
        if (self.timeCounter == null) {
          return false;
        }
        count++;
        self.countDownText(times - count + 1);
        if (count > times) {
          self.showlogin = false;
          clearTimeout(self.timeCounter);
          self.showtime = null;
        } else {
          self.timeCounter = setTimeout(countDownStart, interval);
        }
      }
    },
    goBack() {
      // let url = decodeURIComponent(this.$route.query.redirect);
      // this.$router.replace({ path: url });
      this.$router.go(-1);
    },

    // 确定登录 优化版本
    loginIn() {
      if (!this.skip) {
        let reg = /^1(2|3|4|5|6|7|8|9)\d{9}$/;
        if (!reg.test(this.phone)) {
          this.$toast("电话号码有误");
          return;
        }
      }
      this.$toast.loading({
        message: "登录中...",
        forbidClick: true,
      });
      // alert(JSON.stringify({
      //     mobile: this.phone,
      //     [localStorage.getItem('callbackWay')=='wx' ? "wechatOpenId" : "appleOpenId"]: this.$route.query.openId
      //       ? this.$route.query.openId
      //       : this.openId
      //       ? this.openId
      //       : localStorage.getItem("openId"),
      //     userId: localStorage.getItem("id"),
      //   }) )
      this.$api.login
        .wxBindPhone({
          mobile: this.phone,
          [localStorage.getItem("callbackWay") == "wx"
            ? "wechatOpenId"
            : "appleOpenId"]: this.$route.query.openId
            ? this.$route.query.openId
            : this.openId
            ? this.openId
            : localStorage.getItem("openId"),
          userId: localStorage.getItem("id"),
        })
        .then((res) => {
          this.$toast.clear();
       
          if (res.code === 200) {
            res.data.token&& localStorage.setItem("rtoken",res.data.token)
            this.saveUserInfo(res);
            if (res.data.codeBind) {
              this.$toast({
                message: "登录成功",
                duration: 1500,
                onClose: () => {
                  this.yanzheng = "";
                  this.phone = "";
                  this.countDown(0);
                  localStorage.setItem("token",localStorage.getItem('rtoken'))
                  this.$router.replace({ path: "/scene" });
                  
                },
              });
            } else {
              this.$router.replace(`/inviteIs`);
            }
          } else {
            this.$toast(res.errmsg);
          }
        });
    },
    saveUserInfo(res) {
      res.data.id&&localStorage.setItem("id", res.data.id);
      let userInfo = {
        name: res.data.name,
        mobile: res.data.mobile,
      };
      res.data.id&&localStorage.setItem("userInfo", JSON.stringify(userInfo));
    },
  },
};
</script>
<style lang="less" scoped>
.skip {
  margin-top: 0.5rem;
  display: block;
  text-align: center;
  font-size: 0.28rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #aeaeae;
}

.login {
  height: 100vh;
  background: url("~assets/images/bg_login.png");
  background-size: 100% 100%;
  .meta {
    margin-bottom: 1.68rem;
    padding-top: 0.8rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 0.64rem;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #294bf9;
    img {
      width: 1.8rem;
      height: 1.8rem;
      margin-bottom: 0.2rem;
    }
  }
  .info {
    padding: 0 0.4rem /* 30/50 */;
    ::v-deep.van-field {
      .van-field__body {
        height: 100%;
      }
      height: 1rem;
      background: #f2f3f7;
      border-radius: 0.1rem;
      font-size: 0.32rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #aeaeae;
      padding: 0.28rem 0.2rem;
      box-sizing: border-box;
      input {
        background: transparent;
      }
    }
  }
  .yanzheng {
    margin-top: 0.6rem;
  }
  .yanzheng-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.32rem /* 16/50 */;
    .van-field {
      width: 3.94rem;
      height: 0.9rem;
      background: #f3f3f3;
      border: 1px solid #efefef;
      border-radius: 0.12rem;
      font-size: 0.32rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #aeaeae;
    }
  }
  .getCode {
    width: 2.08rem /* 104/50 */;
    height: 1rem /* 45/50 */;
    background: #f2f3f7;
    border-radius: 0.12rem;

    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.32rem /* 16/50 */;
    font-family: PingFang SC;
    font-weight: 500;
    color: #294bf9 !important;
  }
  .van-button--disabled {
    opacity: 1;
    color: #aeaeae !important;
  }
  .time {
    width: 2.08rem /* 104/50 */;
    height: 1rem /* 45/50 */;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    box-sizing: border-box;
    background: #f2f3f7;
    font-size: 0.28rem;

    color: #294bf9;
    border-radius: 0.1rem;
    font-family: PingFang SC;
    font-weight: 500;
    line-height: 0.4rem;
  }

  /deep/ .van-button--normal {
    padding: 0;
  }
  .btn {
    margin: 1rem 0.4rem 0;
    width: 6.72rem;
    height: 1rem /* 48/50 */;

    background: linear-gradient(360deg, #5b47f5 0%, #486efa 100%);
    border-radius: 5px;
    font-size: 0.4rem /* 18/50 */;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
    border-radius: 0.2rem;
  }
  .extra {
    margin: 0 0.6rem /* 30/50 */ 0.4rem /* 20/50 */;
    width: 6.3rem /* 315/50 */;
    display: flex;
    justify-content: space-between;
    font-size: 0.24rem /* 12/50 */;
    font-family: PingFang SC;
    font-weight: 500;
    color: #4273f8;
    .unable {
      color: #aeaeae;
    }
  }
  .more-text {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 0.24rem /* 12/50 */;
    font-family: PingFang SC;
    font-weight: 400;
    color: #aeaeae;
    margin-bottom: 0.4rem /* 20/50 */;
  }
  .more-login {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 0.32rem;
    .apple {
      width: 0.42rem /* 21/50 */;
      height: 0.5rem /* 25/50 */;
      margin-right: 2.08rem /* 104/50 */;
      margin-bottom: 0.4rem /* 20/50 */;
    }
    .weixin {
      width: 1rem /* 28/50 */;
      height: 1rem /* 22/50 */;
      margin-bottom: 0.2rem /* 20/50 */;
    }
  }
}
.backbox {
  width: 100%;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0);
  padding: 0.12rem /* 6/50 */ 0.32rem /* 16/50 */;
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: space-between;
  font-size: 0.28rem /* 14/50 */;
  position: fixed;
  top: 0;
  left: 0;
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
  z-index: 999;
  .back {
    top: 0.12rem /* 6/50 */;
    left: 0.32rem /* 16/50 */;
    color: #000;
    width: 0.64rem /* 32/50 */;
    text-align: center;
    height: 0.64rem /* 32/50 */;
    line-height: 0.64rem /* 32/50 */;
    border-radius: 50%;
    // background-color: rgba(0, 0, 0, 0.4);
    z-index: 999;
    -webkit-transition: all 0.5s linear;
    transition: all 0.5s linear;
  }
  i {
    opacity: 0;
  }
  &.backFixed {
    background-color: #fff;
    -webkit-transition: all 0.5s linear;
    transition: all 0.5s linear;
    .back {
      -webkit-transition: all 0.5s linear;
      transition: all 0.5s linear;
      background-color: #fff;
      color: #ccc;
    }
    i {
      -webkit-transition: all 0.5s linear;
      transition: all 0.5s linear;
      opacity: 1;
      color: #c83f3fb8;
    }
  }
}
//第三方
.tripartite {
  color: #b1b8cb;
  .tripartite-title {
    margin-top: 1.1rem;
    text-align: center;
    height: 0.24rem;
    font-size: 0.24rem;
    font-family: PingFang SC;
    font-weight: 400;
    color: #aeaeae;
  }
  .tripartite-icon {
    display: flex;
    justify-content: space-around;
    padding: 0 1rem;
    margin-top: 0.64rem;
    > .iconfont {
      font-size: 0.42rem;
    }
  }
}
.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #fff;
  z-index: 99;
}
</style>
